.Facebook_posts_container {
    padding: 0 4.5rem;

    iframe {
        width: 100%;
    }
}

@media (max-width:900px) {

    .Facebook_posts_container {
        padding: 0;
    }

}