.banner-seccion {
    background-color: #FFA52B;
    height: 45vh;
    padding: 0;
}

.isadora_logo_container {
    width: 25rem;
    text-align: center;
}

.isadora_logo_container img {
    width: 85%;
}

.banner_content p {
    color: white;
    margin-bottom: 0;
    font-weight: 300;
    line-height: 1.3rem;

    font-size: 1rem;
}

.banner_btn {
    margin-top: 1rem;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    transition: 0.5s ease;

}

.banner_btn:hover {
    transition: 1s ease;
    background-color: red;
    border: 1px solid red;
}

@media (max-width:765px) {
    .isadora_logo_container {
        width: 25rem;
        text-align: center;
        padding: 2rem;
    }

    .banner-seccion {
        padding: 1rem;
    }
}