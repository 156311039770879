@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
#not-found {
    min-height: 100vh;
    background: url('../../assets/media/bg_404.webp');
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Roboto', sans-serif;
    padding-top: 5rem;
}
body{padding:0; margin:0; box-sizing: border-box;}
#not-found h2 {color:#EE651E; font-size: 30px; font-weight: 900;}
#not-found p {
    color: #97391A;
    font-size: 1.2rem;
    max-width: 650px;
    margin: 0 auto;
}
#search-404 {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 10px;
    border: hidden;
}
.search-flex {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    flex-wrap: nowrap;
    justify-content: center;
    width: 90%;
    max-width: 450px;
    align-content: center;
    align-items: flex-end;
}
.search-flex button{
    padding: 10px;
    border: hidden;
    background: #fff;
    cursor:pointer;
}

