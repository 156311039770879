.spinner{
    position: fixed;
    background-color: #d3d3d366;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.spinner-border{
    position: absolute;
    left: 50%;
    top: 50%;
}