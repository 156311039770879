.btn {
    font-family: var(--font-roboto);
    letter-spacing: 0.125rem;
    font-size: 1rem;
    padding: 0.75rem 1.5rem 0.625rem;
    -webkit-transition: all 0.3s;
    color: var(--color-white);
    line-height: 1;
    transition: all 0.3s;
    border: 0.0625rem solid var(--color-white);
    white-space: nowrap;
    &--shadow {
        font-size: 0.8125rem;
        padding: 0.625rem 1.25rem;
        border: none;
        text-shadow: var(--text-shadow-cta);
        -webkit-box-shadow: var(--box-shadow-cta);
        box-shadow: var(--box-shadow-cta);
    }
    &--black {
        border-color: var(--color-black);
        color: var(--color-black);
    }
    &:hover {
        background-color: var(--color-red-1);
        border-color: transparent;
        color: var(--color-white);
    }
    &Ripple {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation: ripple 0.5s linear both;
        animation: ripple 0.5s linear both;
    }
}

@-webkit-keyframes ripple {
    from {
        width: 0;
        height: 0;
        opacity: 0.5;
    }
    to {
        width: 25rem;
        height: 25rem;
        opacity: 0;
    }
}

@keyframes ripple {
    from {
        width: 0;
        height: 0;
        opacity: 0.5;
    }
    to {
        width: 25rem;
        height: 25rem;
        opacity: 0;
    }
}
