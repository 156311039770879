.alx-no-results{
	font-size: 20px;
}
.alx-no-results-img{
	max-width: 400px;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.alx-no-results{
		font-size: 17px;
	}
	.alx-no-results-img{
		max-width: 250px;
	}
}