.red_bg_hover:hover {
    background-color: red !important;
    border: 1px solid red !important;
    color: white !important;
}

.owl-carousel-home{
    margin-top: 4rem;
}
.related-recipes-product-title {
    font-weight: 600;
    font-size: 3rem;
    text-transform: uppercase;
    color: #97340D;
    margin-bottom: 5rem;
}

.owl-stage-outer {
    margin-left: 4%;
    width: 98%;
    text-align: center;
    z-index: 1;

}

.owl-nav {
    font-size: 5rem !important;
    position: absolute;
    top: 30%;
    width: 105%;
    z-index: 0;
    color: white;

    .owl-prev {
        float: left;
        z-index: 1;
    }

    .owl-next {

        float: right;
        z-index: 9;
    }
}

.owl-item {
    z-index: 0;

    .item {
        z-index: 0;
        text-align: center;
        cursor: pointer;

        img {
            height: auto;
            width: 75%;
            margin: auto;
            object-fit: cover;
            border: .1875rem solid white;
        }
    }
}
.brown_color_text{
    color:#97340d!important;
}

.owl-item>.item>img {
    display: block;
    width: 75% !important;
    height: 260px;
    margin: auto;
}