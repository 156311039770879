.homePageHeader {
    /*background-image: url('../../../assets/images/repeat-banner-arroz.png');
    background-position: center top;
    background-repeat: repeat-x; 
    height: 275px;
    background-size: 2px 275px;
    color: var(--color-white);
    font-family: var(--font-roboto);
    background-image: -webkit-gradient(
        linear,
        left top, left bottom,
        color-stop(43%, var(--color-orange-3)),
        to(var(--color-orange-2))
    );
    background-image: linear-gradient(
        180deg,
        var(--color-orange-3) 43%,
        var(--color-orange-2) 100%
    );*/
    &Background {
        background: url(https://www.frijolesisadora.com/wp-content/uploads/2020/06/fondo-header-top.png)
            no-repeat center;
        background-size: 100% auto;
        max-width: 30.625rem;
    }
    &Img {
        -webkit-transform: scale(0.6) translateY(100%);
        transform: scale(0.6) translateY(100%);
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
    }

   

    
}