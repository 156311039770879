#search-news-form {
    position: relative;
    -webkit-filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.15));
    filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.15));

    #search-news-field {
        width: 100%;
        border: none;
        outline: none;
        border-radius: 100px;
        padding: 5px 50px 5px 20px;
        height: 40px;
        background-color: #FFFFFF;
    } 
    .select-news-field{
        width: 100%;
        border: none;
        outline: none;
        border-radius: 100px;
        padding: 5px 20px 5px 20px;
        height: 40px;
        background-color: #FFFFFF;

    }
    #search-news-button {
        position: absolute;
        right: 15px;
        top: 2px;
    }
    .alx-display-block{
        display: block;
        position: relative;
    }
    .alx-etq-form{
        padding-left: 20px;
        padding-bottom: 10px;
    }
    .alx-sig-input-form{
        margin-bottom: 20px;
    }

}
.alx-cont-filter{
    padding-left: 20px;
}

.circle-btn-orange {
    background-color: #ff8101;
    color: #fff;
    filter: none;

    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0px;

    align-items: center;
    justify-content: center;
}

svg.fa-facebook-f{
    margin-left: 11px;
    margin-top: 7px;
}
svg.fa-twitter{
    margin-left: 9px;
    margin-top: 8px;
}
@media only screen and (max-width: 991px) {

    .alx-cont-filter{
        padding-left: 0px;
    }
  
}
