.search_section {
    padding: 0;

    .search-container {
        padding: 0 5rem;

        .search-title {
            color: #97340D;
            text-transform: uppercase;
            font-weight: 600;
            padding: 3rem 1rem;
        }

        .recipe_img {
            height: 20rem;
            
        }
        .prod_img{
            height: 20rem;

        }
    }
}

@media (max-width:700px) {
    .search_section {

        .search-container {
            padding: .75rem;
        }
    }

}