.recipe-card {
    .recipe-data {
        font-size: 12px;
    }

    .img-box {
        height: 260px;
        width: 100%;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center;
        }
    }
}

.alx-pagination {
  text-align: center;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
}

.alx-link-paginado{
    background-color: #FFFFFF;
    color: #58392C;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin: 0px 5px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 2px 2px 4px #cdcdcd;
    display: inline-block;

}
.alx-link-paginado.activa, .alx-link-paginado:hover{
    background-color: rgb(255, 129, 1);
    color: #FFFFFF;
}