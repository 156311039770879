@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

@font-face {
    font-family: 'nickainleyregular';
    src: url('../../assets/fonts/nickainley-webfont.woff2') format('woff2'),
        url('../../assets/fonts/nickainley-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dk_rosy_leeregular';
    src: url('../../assets/fonts/dk_rosy_lee-webfont.woff2') format('woff2'),
        url('../../assets/fonts/dk_rosy_lee-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-family: 'nickainleyregular';
    font-weight: 400;
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
    color: #ffffff;
    font-size: 85px;
    text-align: center;
}

#slide-home {
    background-color: #ffa52b;
    padding: 100px 0;
}

#slide-home h3 {
    color: #000;
    font-family: 'nickainleyregular';
    font-weight: 400;
    font-size: 70px;
}

#slide-home p {
    color: #000;
    font-size: 15px;
    max-width: 600px;
    margin-bottom: 30px;
}

.modal-content {
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 0%);
    box-shadow: 0 5px 15px rgb(0 0 0 / 0%);
    color: #fff;
    background-color: transparent !important;
    border: hidden;
    text-align: center;
    padding-top: 45vh;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: transparent;
    background-image: none;
    border: hidden;
    border-radius: 0;
    border-bottom: solid 1px #fff;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
}

.close {
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=100);
    opacity: 1;
    text-align: right;
    margin-right: -50px;
}

.modal.in .modal-dialog {
    width: 90%;
    max-width: 80%;
}

#home {
    min-height: 80vh;
    height: 95vh;
    background: url(../../assets/media/Isadora-Home-200826.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -200px;
    padding-top: 170px;
}

.dish {
    width: 350px;
    height: auto;
    margin-bottom: -50px;
}

.conoce {
    border: solid 1px #000;
    color: #000 !important;
}

.icons *,
.copyright * {
    text-decoration: none;
    color: white;
}

.copyright {
    max-width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 15px;
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, from(#ffa52b), to(#f06400));
    background-image: linear-gradient(90deg, #ffa52b 0%, #f06400 100%);
}

.icons {
    margin-bottom: 20px;
}

.form-control:focus {
    color: #fff !important;
    background-color: transparent !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 0%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 0%) !important;
    border-color: transparent;
    border-bottom: solid 1px #fff !important;
}

.nav > li > a:focus,
.nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #fff !important;
    border-bottom: solid 1px #fff;
}

.icons a {
    text-decoration: none !important;
}

.nav > li > a {
    display: inline;
}

#supera {
    min-height: 50vh;
    background: url(../../assets/media/FondoRexturaMadera.png);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 100px 0;
    padding-bottom: 200px;
}

#supera h3 {
    font-family: 'nickainleyregular';
    font-weight: 400;
    font-weight: 400;
    color: #97340d;
    font-size: 70px;
    margin-bottom: 0;
}

#supera h4 {
    font-weight: 700;
    color: #629c23;
    font-size: 44px;
    line-height: 60px;
}

#supera p {
    color: #000;
    font-size: 15px;
    max-width: 400px;
    margin-bottom: 20px;
}

.det {
    max-width: 150px !important;
    margin-top: 20px;
}

.platos {
    position: relative;
}

.platos .dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 85%;
}

#contact-home {
    background: url(../../assets/media/Fondo-textura-mimbre.png);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 100px 0;
    text-align: center;
}

#contact-home h3 {
    color: #ffffff;
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 60px;
}

#contact-home p {
    color: #ffffff;
    text-align: center;
    max-width: 580px;
    margin: auto;
    font-size: 16px;
    margin-bottom: 20px;
}

.visita {
    position: absolute;
}

.visita .butop {
    padding-top: 10px;
}

.header-interna {
    padding-top: 40px;
}

#home-interna {
    min-height: 80vh;
    background: url(../../assets/media/Mi-toque-isadora.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -220px;
    padding-top: 170px;
}

.header-interna .headerItem {
    color: #000 !important;
    font-weight: bolder;
}

.mitoquelogo {
    margin-top: 50px;
}

.baja {
    max-width: 450px;
    margin: auto;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
}

#en-casa .toque h2 {
    text-align: left !important;
    font-size: 45px !important;
}

#en-casa .toque p {
    text-align: left !important;
    margin: 0 !important;
    max-width: 540px !important;
}

#home-interna-frijol {
    min-height: 80vh;
    background: url(../../assets/media/FSA-B1-Backgroun.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -220px;
    padding-top: 170px;
}

#home-interna-frijol h3 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 100px;
}

.flor {
    margin-top: 100px;
}

#nuestro {
    padding: 100px 0;
    text-align: center;
}

#nuestro h3 {
    font-family: 'dk_rosy_leeregular';
    font-size: 89px;
    font-weight: 400;
}

#nuestro p {
    max-width: 400px;
    font-size: 16px;
    margin: 30px auto;
}

.col-frijo h2 {
    font-family: 'Amatic SC', cursive;
    color: #f06400;
    font-size: 65px;
    font-weight: 700;
}

#nuestro .col-frijo p {
    max-width: 400px;
    font-size: 16px;
    margin: 10px auto;
}

#salud {
    padding: 100px 0;
    background-color: #fff1df;
}

#salud h2 {
    color: #020101;
    font-family: 'dk_rosy_leeregular';
    font-weight: 400;
    font-size: 89px;
    max-width: 400px;
}

.bowl {
    text-align: center;
    padding: 100px 0;
}

.bowl h4 {
    font-family: 'nickainleyregular';
    text-align: center;
    color: #f06400;
    font-size: 45px;
    font-weight: 400;
}

.bowl p {
    max-width: 400px;
    font-size: 16px;
    margin: 10px auto;
}

.dashed {
    border-bottom: dashed;
}

.sal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.rela {
    position: relative;
}

.fle1 {
    float: left;
}

.fle2 {
    float: right;
}

#fuente {
    background: #ffb24b;
    padding-bottom: 100px;
}

.fuente-top {
    max-width: 100%;
}

.fuente-datos {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.fuente-datos h2 {
    color: #fff;

    font-weight: 400;
    font-family: 'Amatic SC', cursive;
    font-size: 60px;
}

.fuente-datos p {
    font-size: 16px;
    margin: 10px 0;
}

.fuente-datos div {
    padding: 15px;
}

.tillo-fle {
    position: absolute;
    right: -170px;
    top: 50px;
}

.tillo {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sixtillo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tillop {
    font-size: 16px;
    color: #000;
    max-width: 300px;
    margin: auto;
}

.tilloas {
    font-size: 13px;
    color: #000;
    max-width: 300px;
    margin: auto;
}

#mexico {
    padding: 100px 0;
    position: relative;
}

#mexico .container {
    position: relative;
}

.plafoot {
    width: 300px;
    position: absolute;
    top: -250px;
    right: 0;
}

#mexico h2 {
    color: #020101;
    font-family: 'dk_rosy_leeregular';
    font-weight: 400;
    font-size: 89px;
    max-width: 400px;
}

#mexico h3 {
    font-family: 'Amatic SC', cursive;
    color: #f06400;
    font-size: 65px;
    font-weight: 700;
}

#mexico p {
    font-size: 16px;
    margin: 10px 0;
}

.mex {
    margin-top: -100px;
}

.linn {
    margin: 20px 0;
}

#anuncio {
    background-color: rgba(0, 0, 0, 0.7);
}

#anuncio .modal-content {
    padding-top: 0;
    max-width: 700px;
    margin: auto;
}

#anuncio .modal-content img {
    max-width: 700px;
    border: solid 10px #fff;
}

.main-title {
    font-size: 2.8rem;
}

.h3-normal-fw {
    font-weight: 400;
}

#PlatoPrincipalHome {
    width: 80%;
}

#version-ingles-header {
    position: absolute;
    top: -2px;
    right: 10%;
    padding: 0.5rem 1rem;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2px;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    fill: #ffffff;
    color: #ffffff;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
            box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

/*#alx-mitad{
    height: 275px;
    width: 1px;
    background-color: #000000;
    position: absolute;
    left: 50%;
    top: 0px;
}*/
#alx-background-arroz {
    background-image: url('../../assets/images/banner-2-center-naranja.png');
    background-position: 0px 0px;
    background-repeat: repeat-x; 
    height: 275px;
    background-size: 15px 185px;
    overflow: hidden;
    padding-top: 0px !important;
    position: relative;
}
#alx-background-arroz-int{
    background-image: url('../../assets/images/banner-2-center-amarillo.png');
    background-position: 0px bottom;
    background-repeat: repeat-x; 
    height: 275px;
    background-size: 15px 90px;
    overflow: hidden;
    position: relative;
}
#alx-banner-flecha-izq{
    background-image: url('../../assets/images/banner-2-transparten-izq.png');
    background-position: left top;
    background-repeat: no-repeat;
    height: 185px;
    width: 187px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
}
#alx-banner-flecha-der{
    background-image: url('../../assets/images/banner-2-transparente-der.png');
    background-position: right top;
    background-repeat: no-repeat;
    height: 185px;
    width: 187px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
}
/*#alx-banner-amarillo{
    background-image: url('../../assets/images/banner-amarillo.png');
    background-position: 0px 0px;
    background-repeat: repeat-x;
    background-size: 1px 63px;
    height: 63px;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1;
}*/
#alx-banner-letras{
    background-image: url('../../assets/images/banner-2-texto.png');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 227px;
    width: 517px;
    position: absolute;
    left: 50%;
    top: 8px;
    margin-left: -495px;
    z-index: 2;
}
#alx-banner-letras-venta{
    background-image: url('../../assets/images/banner-2-walmart.png');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 51px;
    width: 419px;
    position: absolute;
    left: 50%;
    bottom: 24px;
    margin-left: -444px;
    z-index: 2;
}
#alx-banner-producto{
    background-image: url('../../assets/images/banner-2-productos.png');
    background-position: 0px center;
    background-repeat: no-repeat;
    height: 275px;
    width: 499px;
    position: absolute;
    left: 50%;
    top: 0px;
    margin-left: -45px;
    z-index: 2;
}
#alx-btn-nuevo-banner{
    background-image: url('../../assets/images/banner-2-nuevos.png');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    height: 77px;
    width: 220px;
    position: absolute;
    left: 50%;
    top: 0px;
    margin-left: -630px;
    z-index: 2;
}
#alx-boton-usa{
    z-index: 3;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
}

/*@media screen and (max-width: 1220px) {
    #alx-btn-nuevo-banner{
        margin-left: -600px;
    }
}*/

@media screen and (max-width: 1220px) {
    #alx-btn-nuevo-banner{
        margin-left: -495px;
        height: 52px;
        width: 147px;
        background-size: 100% auto;
    }
}


@media screen and (max-width: 991px) {
    section#home {
        background: url(../../assets/media/home-movil.webp);
        background-size: cover;
        background-position: center 200px;
        background-repeat: no-repeat;
    }

    .headerNav {
        background-color: #ffa72b;
    }

    .headerItem {
        color: white !important;
    }

    #banner {
        display: none;
    }

    header {
        padding-top: 0;
    }

    .redes {
        z-index: 1;
        top: 110px;
    }

    h1 {
        font-size: 55px;
    }

    .fle img {
        display: none;
    }

    .fle {
        text-align: center;
        padding-bottom: 50px;
    }

    #en-casa h2 {
        font-size: 30px;
    }

    .vector1 {
        display: none;
    }

    .vector3 {
        display: none;
    }

    #en-casa p {
        margin-bottom: 30px;
    }

    #cebolla-png,
    #frijoles-scnd-img {
        display: none;
    }

    .frij {
        display: block;
        text-align: center;
    }

    .frig img {
        max-width: 100%;
    }

    #slide-home h3 {
        font-size: 50px;
    }

    .mifle {
        display: none;
    }

    #slide-home {
        text-align: center;
    }

    #supera {
        text-align: center;
        background: url(../../assets/media/FondoRexturaMadera.png);
        background-size: cover;
        background-position: center;
    }

    #supera h3 {
        font-size: 50px;
    }

    .platos .dot {
        width: 65%;
    }

    #contact-home h3 {
        font-size: 35px;
        line-height: 40px;
    }

    .footerMenu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-top: 50px;
        color: white;
        text-decoration: none;
        text-align: center;
    }

    .dish {
        width: 150px;
        height: auto;
        margin-bottom: -10px;
    }

    .copyright {
        text-align: center;
    }

    .taar {
        text-align: center;
    }

    #anuncio .modal-content img {
        max-width: 700px !important;
        border: solid 10px #fff;
        margin: auto !important;
    }

    #anuncio .modal-dialog {
        width: 90% !important;
        max-width: 90% !important;
        margin: auto;
        margin-top: 100px;
    }

    .visita {
        display: none !important;
    }

    .header-interna {
        padding-top: 0;
    }

    .header-interna .headerItem {
        color: #fff !important;
        font-weight: bolder;
    }

    #en-casa .toque h2 {
        font-size: 30px !important;
        line-height: 40px;
        text-align: center !important;
    }

    #en-casa {
        padding: 50px 0;
        min-height: 400px;
        position: relative;
        padding-bottom: 50px;
        text-align: center;
    }

    #en-casa .toque p {
        text-align: center !important;
        margin: 0 !important;
        max-width: 540px !important;
    }

    .platos .dot {
        width: 55%;
    }

    #nuestro h3 {
        font-family: 'dk_rosy_leeregular';
        font-size: 49px;
        font-weight: 400;
    }

    #salud h2 {
        font-size: 49px;
        text-align: center;
    }

    .bowl {
        padding: 50px 0;
    }

    .fuente-datos {
        display: block;
        text-align: center;
    }

    .plafoot {
        display: none;
    }

    #mexico h2 {
        text-align: center;
        font-size: 49px;
    }

    #mexico h3 {
        font-size: 45px;
        text-align: center;
    }

    #mexico p {
        text-align: center;
    }

    .fle1 {
        display: none;
    }

    .fle2 {
        display: none;
    }

    .redes {
        z-index: 1;
        top: 110px;
        width: 94%;
        left: 2%;
        right: 2%;
    }

    section {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .tillo {
        display: block;
        text-align: center;
        overflow: hidden;
    }


    
    #alx-boton-usa{
        display: none;
    }

    #alx-background-arroz {
        height: 275px;
        background-size: 15px 185px;
    }
    #alx-background-arroz-int{
      
        height: 275px;
        background-size: 15px 90px;
       
    }
    #alx-banner-flecha-izq{
       
        height: 185px;
        width: 187px;
       
    }
    #alx-banner-flecha-der{
       
        height: 185px;
        width: 187px;
       
    }
  
    #alx-banner-letras{
       
        height: 114px;
        width: 260px;
        background-size: contain;
       
        top: 0px;
        margin-left: -130px;
    }
    #alx-banner-letras-venta{
       
        height: 31px;
        width: 262px;
      
        bottom: 10px;
        margin-left: -120px;
        background-size: contain;
        text-align: center;
    }
    #alx-banner-producto{
       
        height: 172px;
        width: 312px;
       
        top: 80px;
        margin-left: -156px;
        background-size: contain;
    }
    #alx-btn-nuevo-banner{
        margin-left: -200px;
        left: 50%;
        height: 39px;
        width: 110px;
        background-size: 100px auto;
    }
   
}

@media screen and (max-width: 565px) {

    #alx-btn-nuevo-banner{
     
        height: 39px;
        width: 110px;
        background-size: 100px auto;
      
        top: 0px;
        margin-left: 0px;
        left: 0px;
    }
    #alx-banner-flecha-der{
       
        height: 185px;
        width: 187px;
        display: none;
       
    }

}

.alx-plato-img-top{
    /*margin-top: 33px;*/
    /*max-width: 110px;*/
    /*margin: 33px auto 23px auto;*/
    /*margin: 23px auto 16px auto;*/
    margin: 23px 0px 16px 0px;
    margin-right: 6px !important;
    max-width: 80px;
}



@keyframes entradaizq {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#alx-banner-letras {
  animation: entradaizq 1s ease-in-out forwards;
}


@keyframes entrada {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#alx-banner-producto {
  animation: entrada 1s ease-in-out forwards;
}


@keyframes entradatop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#alx-btn-nuevo-banner {
  animation: entradatop 1.5s ease-in-out forwards;
}

@keyframes entradaaparece {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#alx-banner-letras-venta {
  animation: entradaaparece 1.5s ease-in-out forwards;
}

