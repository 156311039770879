@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';

.super-alimento-banner {
    background-image: url('../../assets/images/Home-B4-Background.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &__headline {
        color: var(--color-brown);
        font-family: var(--font-nickainleyRegular);
        font-size: clamp(4.8125rem, 6vw, 5.375rem);
        span {
            color: var(--color-green);
            font-family: var(--font-roboto);
            font-weight: 600;
            font-size: clamp(2.25rem, 4vw, 3.125rem);
        }
    }
    p {
        font-family: var(--font-roboto);
        line-height: 1.4;
    }
    &__description {
        max-width: 25rem;
    }
    &__img-container {
        width: 70%;
    }
    &__img-desc {
        width: 30%;
        max-width: 10rem;
        left: 5%;
        @include media-breakpoint-up(lg) {
            top: 3.125rem;
        }
        @include media-breakpoint-up(xl) {
            left: 12%;
        }
        img {
            width: 90%;
            max-width: 8.125rem;
            margin-left: 30%;
        }
        p {
            font-size: 0.9375rem;
            line-height: 1.3;
        }
    }
}
