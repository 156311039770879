@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400;1,500;1,900&family=Montserrat&display=swap');

@font-face {
    font-family: 'NickainleyRegular';
    src: url('../assets/fonts/Nickainley-Normal2.otf') format('opentype'),
        url('../assets/fonts/NickainleyRegular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HKGroteskRegular';
    src: url('../assets/fonts/HKGrotesk-Regular.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HKGroteskBold';
    src: url('../assets/fonts/HKGrotesk-Bold.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HKGroteskSemiBold';
    src: url('../assets/fonts/HKGrotesk-SemiBold.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoBold';
    src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}