@use '../../../styles/variables.scss' as *;
@import '../../../styles/bootstrap-utilities.scss';

.comoHechosEnCasa {
    --font-size-headline: 2.25rem;
    --middle-second-img-position: absolute;
    --middle-second-img-animation: fadeIn 8s linear both infinite;
    --middle-second-img-transform: translateX(-50%);
    --middle-img-width: 80%;
    @include media-breakpoint-up(md) {
        --font-size-headline: 3.125rem;
    }
    @include media-breakpoint-up(lg) {
        --middle-second-img-position: static;
        --middle-second-img-animation: none;
        --middle-second-img-transform: none;
        --middle-img-width: 45%;
        --section-padding-bottom: 0;
        padding-bottom: 0;
    }
    * {
        font-family: var(--font-roboto);
    }
    &Headline {
        color: var(--color-orange-5);
        font-size: var(--font-size-headline);
        line-height: 1;
        font-weight: 400;
    }
    &Description {
        max-width: 28.125rem;
    }
    &ImgDesc {
        font-size: 0.9375rem;
        max-width: 17.1875rem;
    }
    &Flecha {
        opacity: 0.4;
        width: 20%;
        max-width: 3.0625rem;
        max-height: 4.0625rem;
    }
    // &Flecha,
    // &RightColImgSecond,
    // &LeftImg {
    //     width: 70%;
    //     padding-top: 6rem;
    //     margin-bottom: -3rem;
    // }
    &MiddleImg {
        width: var(--middle-img-width);

        &Second {
            position: var(--middle-second-img-position);
            top: 0;
            left: 50%;
            -webkit-transform: var(--middle-second-img-transform);
            transform: var(--middle-second-img-transform);
            -webkit-animation: var(--middle-second-img-animation);
            animation: var(--middle-second-img-animation);
        }
    }

    &RightColImg {
        &Second {
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            z-index: -1;
        }
    }
}
