.alx-detalle-news-cuerpo{
    word-wrap: break-word;
}
#alx-cont-share{
	float: right;
}
.alx-link-externo, .alx-detalle-news-cuerpo a{
    color: #ff8101;
    font-weight: bold;
}
.alx-link-externo:hover, .alx-detalle-news-cuerpo a:hover{
    color: #ff8101;
    font-weight: bold;
    text-decoration: underline;
}
.alx-btn-atras{
	background-image: url('../../assets/images/atras.png');
    background-position: 15px center;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    background-size: 15px auto;
    color: #58392c;
    width: 100px;
    border: none;
    outline: none;
    border-radius: 100px;
    padding: 0px 0px 0px 35px;
    height: 40px;
    line-height: 40px;
    display: block;
    font-weight:bold;
    margin-bottom: 40px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);

}

.alx-titulo-noticia{
    font-family: inherit;
    transform: rotate(0deg);
}

@media screen and (min-width: 768px){
  
    
}

@media screen and (min-width: 992px){
    
}