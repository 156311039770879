.modal_search_input_container {
    width: 100%;
    padding-top: 2rem;
    input.search_input {
        padding: 2.5rem;
        background-color: transparent;
        border-bottom: 1px solid #ddaeae;
        width: 72%;
        color: white;
        font-size: 3rem;
        text-align: center;
    }
}
.ReactModal__Overlay {
    background-color: transparent !important;
    z-index: 999 !important;
}
.close_modal {
    padding-top: 3rem;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 3rem;
    font-weight: 300;
}

.search_icon_container {
    background-color: white;
    border-radius: 50rem;
    padding: 0.25rem;
    margin-left: 0.3125rem;
    cursor: pointer;
    .nav_search_icon {
        width: 1rem;
        margin: 0 0.2rem 0.2rem;
        -webkit-filter: invert(100%) sepia(100%) saturate(100%)
            hue-rotate(100deg) brightness(00%) contrast(0%);
        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg)
            brightness(00%) contrast(0%);
        z-index: 99;
    }
}
