@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';

.nav {
    --nav-bg: var(--color-orange-4);
    --nav-item-color: var(--color-white);
    --nav-item-bgcolor-hover: var(--color-dark-grey);
    --nav-item-line-display: none;
    @include media-breakpoint-up(lg) {
        --nav-bg: transparent;
        --nav-item-color: var(--color-black);
        --nav-item-bgcolor-hover: transparent;
        --nav-item-line-display: block;
    }
    background-color: var(--nav-bg);
    z-index: 999;
    max-width: 71.25rem;
    &Brand {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        &Image {
            width: 60%;
            max-width: 10.3125rem;
        }
    }
    &Item {
        color: var(--nav-item-color);
        font-weight: 500;
        font-family: var(--font-roboto);
        padding: 0.625rem 1.25rem;
        -webkit-transition: background-color 0.4s ease;
        transition: background-color 0.4s ease;
        &:hover {
            background-color: var(--nav-item-bgcolor-hover);
            color: var(--nav-item-color);
            &::after {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                opacity: 1;
            }
        }
        &::after {
            content: '';
            position: absolute;
            width: calc(100% - 2.5rem);
            height: 0.125rem;
            background-color: var(--color-black);
            bottom: -0.125rem;
            left: 1.25rem;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            transition: transform 0.4s ease, opacity 0.4s ease,
                -webkit-transform 0.4s ease;
            opacity: 0;
            display: var(--nav-item-line-display);
        }
        &White {
            color: var(--color-white);
            &:hover {
                color: var(--color-white);
            }
            &::after {
                background-color: var(--color-white);
            }
        }
        &Brown {
            color: #97340d;
            &:hover {
                color: #97340d;
            }
            &::after {
                background-color: #97340d;
            }
        }
        &Image {
            -ms-flex-preferred-size: 16.3125rem;
            flex-basis: 16.3125rem;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            margin-left: 5rem;
        }
    }
}