@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';

.footer {
    --footer-pt: 3.125rem;
    --footer-logo-max-width: 12.5rem;
    --footer-img-width: 60%;
    --footer-img-max-width: 18.75rem;
    @include media-breakpoint-up(md) {
        --footer-img-container-margin-top: -9.375rem;
    }
    @include media-breakpoint-up(lg) {
        --footer-pt: 9.375rem;
        --footer-img-container-margin-top: 0;
        --footer-img-container-position: absolute;
        --footer-img-width: 23vw;
        --footer-img-max-width: 25rem;
    }
    @include media-breakpoint-up(xl) {
        --footer-nav-padding-bottom: 10.625rem;
        --footer-logo-max-width: 18.125rem;
    }
    background-image: linear-gradient(
        180deg,
        var(--color-orange-5) 0%,
        var(--color-orange-4) 100%
    );
    padding-top: var(--footer-pt);
    &Logo {
        max-width: var(--footer-logo-max-width);
    }
    &Nav {
        padding-bottom: var(--footer-nav-padding-bottom);
        &Title {
            font-family: var(--font-hkGroteskBold);
            font-size: 1.25rem;
        }
        &Link {
            font-family: var(--font-hkGroteskRegular);
            transition: 0.4s ease;
            &:hover {
                color: var(--color-black);
                text-decoration: underline;
                text-decoration-color: var(--color-white);
                text-underline-offset: 0.1875rem;
            }
        }
    }
    &Paragraph {
        font-family: var(--font-roboto);
        max-width: 12.5rem;
    }

    &Img {
        width: var(--footer-img-width);
        max-width: var(--footer-img-max-width);
        &Container {
            position: var(--footer-img-container-position);
            bottom: 0;
            &.footerImgContainerOverride {
                margin-top: var(--footer-img-container-margin-top);
            }
        }
    }
    &Copyright {
        background-image: linear-gradient(
            90deg,
            var(--color-orange-4) 0%,
            var(--color-orange-5) 100%
        );
    }
}
