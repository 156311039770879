.related_recipes_section {
    padding: 8rem 25rem;
    background-color: #F3E0CB;
    text-align: center;
    .related-recipes-title {
        font-family: 'NickainleyRegular';
        font-weight: 300;
        font-size: 4rem;
        color: #97340D;
    }
    .related-recipes-product-title {
        font-weight: 600;
        font-size: 3rem;
        text-transform: uppercase;
        color: #97340D;
        margin-bottom: 5rem;
    }
    
    .owl-stage-outer {
        margin-left: 4%;
        width: 98%;
        text-align: center;
        z-index: 1;
        
    }
    
    .owl-nav {
        font-size: 5rem !important;
        position: absolute;
        top: 30%;
        width: 105%;
        z-index: 0;
        color: white;
        
        .owl-prev {
            float: left;
            z-index: 1;
        }
        
        .owl-next {
            
            float: right;
            z-index: 9;
        }
    }
    
    .owl-item{
        z-index: 0;
        .item {
            z-index: 0;
            text-align: center;
            cursor: pointer;
            
            img {
                height: auto;
                width: 100%;
                object-fit: cover;
                border: .1875rem solid white;
            }
            .recipe_title{
                color: #97340D;
                
            }
        }
    }
}


@media (max-width: 800px) {
    .related_recipes_section {
        padding: 3rem;
        .related-recipes-title {
            font-size: 3rem;
        }
        .related-recipes-product-title {
            font-size: 1.5rem;
        }
    }
}
@media (max-width: 1200px) {
    .related_recipes_section {
        padding: 5rem;
    }
}
@media (max-width: 1600px) {
    .related_recipes_section {
        padding: 3rem 8rem;
    }
}
