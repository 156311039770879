.faq-row {
  margin: 10rem 0 !important;
  padding: 0 3rem;
}

.faq-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.75rem;
}

.question-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 1.25rem 0;
}

.question {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .faq-title {
    font-size: 1.25rem;
  }
}