@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';

.social-icons {
    z-index: 999;
    --color-icon: var(--color-white);
    @include media-breakpoint-up(lg) {
        --color-icon: var(--color-black);
        position: absolute;
    }
    &__link {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        &:not(:last-child) {
            margin-right: 0.875rem;
        }
        &:hover {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
        svg {
            color: var(--color-icon);
            font-size: 2rem;
        }
        &--usFlag {
            width: 2rem;
        }
        &--button {
            background: none;
            outline: none;
            svg {
                font-size: 1.5rem;
            }
        }
    }
}

.color_brown{
    color: #97340d!important;
}