
/*
*   Modal Cookies
*/


.btn-moreinfo {
    background-color: white;
    border: none;
    cursor: pointer;
    width: 300px;
    height: 60px;
    margin-bottom: 15px;
}

.btn-aceptar {
    background-color: #FFED00;
    text-decoration: none;
    padding: 6px 30px;
    color: #000;
    font-size: 12px;
}

.buttons-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-end;
    /* height: 150px; */
}

.popup-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
}

.popup-fraude {
    background-color: #333333cf;
    color: white;
    width: 100%;
    height: 230px;
    display: flex;
    align-items: center;
    transition: height 1s ease;
    /* padding: 0px 100px; */
}

.btn-cerrar {
    border: none;
    cursor: pointer;
    opacity: 0.8;
    width: 300px;
    height: 60px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.btn-aceptar,
.btn-cerrar,
.btn-moreinfo {
    font-weight: 700;
}

.more-info {
    display: none;
    margin: 20px 0px;
}

.more-info div {
    width: 50%;
}

.popup-fraude p {
    z-index: 1;
    margin: 0;
    max-width: 700px;
    line-height: 26px;
}

.popup-cookies p {
    margin: 0;
    font-size: 12px;
}

.popup-title {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    font-weight: 700;
}

.more-info a {
    color: yellow;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
}

.popup-cookies {
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 20px;
    background-color: white;
}

.leermas-link {
    text-decoration: none;
    color: #000;
    font-weight: 700;
}

.fraude-txt {
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 20px;
    border: transparent;
}

.expanded {
    height: 330px;
}

.expanded .more-info {
    display: flex;
}

.expanded .fraude-txt {
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 20px;
}

.close {
    /* display: none; */
    transform: translateY(130%);
}

#information {
    transition: height .5s ease, transform .5s ease;
}

.dudas-container {
    display: none;
}

.expanded .dudas-container {
    display: block;
}

.bold-txt {
    font-weight: 700;
}

.dudas-container p {
    margin-bottom: 10px;
}

.popup-container .container {
    display: flex;
    justify-content: space-between;
}

#more-info{
    display: none;
    flex-direction: row-reverse;
}

@media(max-width:980px) {
    .popup-container .container {
        flex-direction: column;
        align-items: center;
    }

    .popup-fraude {
        height: 100%;
        padding: 30px 0px
    }

    .btn-moreinfo,
    .btn-cerrar {
        width: 190px;
        height: 50px;
    }

    .popup-title {
        font-size: 24px;
    }

    .popup-fraude p {
        font-size: 14px;
    }

    .popup-cookies {
        height: 100%;
        padding: 10px 20px;
    }

    .buttons-container {
        flex-direction: row;
        margin: 10px 0px;
    }

    .btn-moreinfo {
        margin-bottom: 0px;
    }

    .btn-moreinfo,
    .btn-cerrar {
        margin: 0px 10px;
    }
}

@media(max-width:560px) {
    .popup-cookies {
        display: flex;
        flex-direction: column;
    }

    .btn-aceptar {
        margin-top: 10px;
    }

    .buttons-container {
        flex-direction: column;
    }

    .btn-moreinfo,
    .btn-cerrar {
        margin: 6px 0px;
    }
    .more-info{
        display: none!important;
    }
    h2.popup-title{
        text-align: center;
    }
}


/*
*   Modal Cookies
*/