:root {
    /* Colors */
    --color-white: rgba(255, 255, 255);
    --color-black: rgb(0, 0, 0);
    --color-black-1: #020101;
    --color-orange-1: #fdbb2d;
    --color-orange-2: #ff9637;
    --color-orange-3: #f06601;
    --color-orange-4: #ffa52b;
    --color-orange-5: #f06400;
    --color-red-1: #e3012b;
    --color-dark-grey: #55595c;
    --color-brown: #97340d;
    --color-green: #629c23;

    /* Fonts */
    --font-montserrat: 'Montserrat', sans-serif;
    --font-roboto: 'Roboto', sans-serif;
    --font-nickainleyRegular: 'NickainleyRegular', sans-serif;
    --font-hkGroteskRegular: 'HKGroteskRegular', sans-serif;
    --font-hkGroteskBold: 'HKGroteskBold', sans-serif;
    --font-hkGroteskSemiBold: 'HKGroteskSemiBold', sans-serif;

    /* Shadows Cta */
    --box-shadow-cta: 0 0 0.625rem rgba(0, 0, 0, 0.5);
    --text-shadow-cta: 0 0 0.625rem rgba(0, 0, 0, 0.3);
}

/* Media Queries */
@mixin breakpoint($screenSize) {
    @media (min-width: $screenSize) {
        @content;
    }
}
/* Animaciones */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Bootstrap media queries: usar estos media queries para respetar la grilla de bootstrap*/

/* @include media-breakpoint-up(sm) { ... }
@include media-breakpoint-up(md) { ... }
@include media-breakpoint-up(lg) { ... }
@include media-breakpoint-up(xl) { ... }
@include media-breakpoint-up(xxl) { ... } */
