.single_recipe_section {
    padding: 0;
}

.single_recipe_img {
    height: 28rem !important;
    width: 100%;
    object-fit: cover;
    margin-top: 2rem;
    border: 3px solid white;
}

.single_recipe_title {
    font-size: 3.1rem;
    font-weight: 700;
    color: #F06400;
    text-transform: uppercase;
    margin-top: 1rem;
}

.underine_bar {
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    margin: 1rem 0 2rem 0;
}

.single_recipe_time_difficulty_socials_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single_recipe_time_difficulty_container {
    display: flex;
}

.single_recipe_difficulty_container {
    margin-left: 8rem;
}

.color_orange {
    color: #F06400;
    font-weight: 600;
    margin-bottom: 0;
}

.second_recipe_titles {
    color: #97340D;
    font-weight: 600;
    text-transform: uppercase;
}

.ingredient,
.sub_ingredient {
    margin-bottom: 0;
}

.ingredient:before {
    content: '✓';
}

.sub_ingredient {
    margin: 1rem 0;
    font-weight: bold;
    text-transform: uppercase;
}

.facebook_logo,
.twitter_logo {
    filter: invert(48%) sepia(50%) saturate(1600%) hue-rotate(344deg) brightness(94%) contrast(114%);
    width: 1.6rem;
    margin-right: 0rem;
    cursor: pointer;
}

.facebook_logo {
    margin-right: 1.6rem;
}

.social_container {
    display: flex;

}

.color_brown{
    color: #97340D;
}