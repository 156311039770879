.Product_section {
    padding: 5rem 0;
    max-width: 73.125rem;


    .title_top {
        color: #f06400;
        font-family: 'NickainleyRegular';
        font-size: 4rem;
        font-weight: 300;
        margin-top: 1rem;
    }

    .robotoRegular {
        font-family: 'RobotoRegular';
    }

    .hk-grotesk {
        font-family: 'HKGroteskSemiBold';
    }

    .title_bottom {
        color: #f06400;
        font-weight: 700;
        font-size: 3rem;
        text-transform: uppercase;
    }

    .tab {
        overflow: hidden;
        margin-top: 4rem;

        button {
            float: left;
            cursor: pointer;
            padding: 14px 16px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            font-size: 17px;
            background-color: white;
        }

        .active_tab {
            border: 1px solid#d4d4d4;
            border-bottom: none;
            color: #f06400;
        }

        .disactivated_Tab {
            border: none;
            color: #126eb5;
        }

        .nutritional_img {
            width: 100%;
        }
    }

    .tabcontent {
        padding: 6px 12px;
        border: 1px solid #ccc;
        margin-top: -1px;
        padding: 2rem;
    }

    .img_container {
        text-align: center;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
        img {
            width: 94%;

        }

        img:hover {
            opacity: 0;
        }
    }
}

@media (max-width: 900px) {
    .Product_section {
        padding: 1rem;

        .prod_details_col.col{
            flex: auto;
        }
    }
}