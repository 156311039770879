#Noticia {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.mainTitlePost {
    margin: 2rem 0;
    color: #4e4e4e;
    font-weight: 600;
    font-family: 'Roboto';
    transform: rotate(0deg);
    text-align: left;
}

.mainImage {
    display: flex;
    justify-content: center;
    margin: 0 6rem 0 6rem;
}

.icon {
    font-size: 25px;
    color: #464646;
    margin-right: 3.5rem !important;
}

.shadow-text {
    text-shadow: 2px 1px 2px #565656;
}

p.card-text {
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 1px;
}

h5.card-title {
    font-size: 0.8rem;
}

.card {
    max-height: 20rem;
    max-width: 30rem;
    overflow: hidden;
}

.linear-bottom {
    border-bottom: 2px solid #ffffff;
}

.other-news{
    margin-top: 7rem;
}

#othersTitle{
    margin: 0 0 1rem 3rem;
    font-size: 20px;
    color: #818181;
}

#body-inner-col, #body-inner-col>p, .mainTitlePost{
    color: #603811 !important;
}

.icon{
    color: #464646;
}

b{
    font-weight: bold; 
    color: #603811;
}

#divisor-hr{
    padding: 0 2rem 0 0;
}

.rrss{
    width: min-content;
    padding: 0rem 2rem 0 0;
}

#fixedCopied{
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1.5rem;
    margin: 1rem;
    background-color: #464646;
    color: white;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 1s ease;
}