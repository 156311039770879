.privacy-policy-row {
  margin-top: 8rem;
  padding: 0 3rem;
}

.privacy-notice {
  padding: 0;
  margin: 9rem 0 5rem 0;
}

.notice-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.75rem;
  font-family: "KH-Grotesk", Sans-serif;
}

.policy {
  margin: 3rem 0 6rem 0;
  padding: 0 0;
}

.policy-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  font-family: "KH-Grotesk", Sans-serif;
}

@media screen and (max-width: 991px) {
  .notice-title {
    font-size: 2.5rem;
  }
}
