.contactTitle {
  color: #f06400;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
  margin: 2rem 0 2rem 0;
}

.contactButton {
  background-color: #f06601;
  color: white;
  padding: 0.5rem 1.5rem!important;
}

.form-check-label{
  padding-right: 3rem;
}

.linea-vv-container{
  display: contents;
}

.line-verde-valle-logo{
  /*padding-top: 13rem;
  margin-right: -13rem;
  margin-left: 2rem;*/
  margin: 0px auto 50px;
  width: 13.8rem;
  filter : invert(34%) sepia(50%) saturate(1600%) hue-rotate(168deg) brightness(94%) contrast(114%);
}

.contactFormField {
  display: block;
  width: 100%;
  padding: 0.65rem 0.85rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #818a91;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.errors{ color: #f03000; }
.errors, .success{
  text-align: center;
  font-weight: 600;
}

.robotoBold{
  font-family: 'RobotoBold';
  transform: rotate(0deg);
}

.robotoReg
{
  font-family: 'RobotoRegular';
}
select.form-select{
  border: 1px solid #818a91;;
}

#contactForm>div>input{
  font-family: 'RobotoRegular';
}

#contactForm>div>div>label{
  font-family: 'RobotoRegular';
}

#submitBtn
{
  background-color: #F06400;
  color: #FFFFFF;
  min-height: 40px;
  text-transform: uppercase;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 0px 0px 0px 0px;
  font-family: 'RobotoRegular';
  font-weight: 600;
  letter-spacing: normal;
}
.alx-link-tel{
    display: block;
    margin: 0px auto;
}

@media(max-width:1000px) {
  .line-verde-valle-logo{
    margin-right: 0rem;
    margin-left: 0rem;
  }
}
@media(max-width:768px) {
  
  .line-verde-valle-logo{
    padding: 0rem 0;
  }
  .contactTitle {
    font-size: 2rem;
  }
}

.grecaptcha-badge { 
	visibility: visible !important;
}