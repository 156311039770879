@forward './fonts.scss';
@use './variables.scss' as *;
//Bootstrap
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
//Bootstrap utilidades sass;
@import './bootstrap-utilities.scss';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html.no-smooth {
    overflow-x: hidden;
    scroll-behavior: initial;
}

a {
    text-decoration: none;
    color: var(--color-white);
    &:hover {
        color: var(--color-white);
    }
}
p {
    font-size: 1rem;
}
header {
    position: relative;
    z-index: 10;
}
ul {
    list-style-type: none;
}
section {
    padding: 3.125rem 0;
    @include media-breakpoint-up(md) {
        padding: calc(3.125rem * 2) 0;
    }
}

.roboto-regular {
    font-family: 'Roboto';
    font-weight: 500;
}

.roboto-bold {
    font-family: 'Roboto';
    font-weight: 600;
}

.cursive-nickainley {
    font-family: 'NickainleyRegular';
    font-weight: 400;
    word-spacing: 5px;
}

.cursive-nickainley-bold {
    font-family: 'NickainleyRegular';
    font-weight: 600;
    word-spacing: 15px;
}

.hk-grotesk-reg {
    font-family: 'HKGroteskRegular';
}

.hk-grotesk-sb {
    font-family: 'HKGroteskSemiBold';
}

.hk-grotesk-bold {
    font-family: 'HKGroteskBold';
}

.naranja-isa {
    color: #f06400;
}

.negro-isa {
    color: #020101;
}

.negro-isa {
    color: #020101;
}

.white-text {
    color: white;
}

.fs-70 {
    font-size: 70px;
}

.fs-60 {
    font-size: 60px;
}

.fs-50 {
    font-size: 50px;
}

.fs-40 {
    font-size: 40px;
}

.fs-1 {
    font-size: 1rem;
}

.fs-2 {
    font-size: 2rem;
}

.fs-3 {
    font-size: 3rem;
}

.fs-4 {
    font-size: 4rem;
}

.fs-5 {
    font-size: 5rem;
}

.upper-text {
    text-transform: uppercase;
}

.cap-text {
    text-transform: capitalize;
}

.m-9 {
    margin: 9rem;
}

.m-8 {
    margin: 8rem;
}

.m-7 {
    margin: 7rem;
}

.m-6 {
    margin: 6rem;
}

.m-5 {
    margin: 5rem;
}

.mv-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
}

.mv-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.mv-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.mv-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.mv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.pt-6 {
    padding-top: 6rem;
}

.pt-7 {
    padding-top: 7rem;
}

.pt-8 {
    padding-top: 8rem;
}

.w-100 {
    width: 100%;
}

.bg-naranja-isa {
    background-color: #ffa52b;
}