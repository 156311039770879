.recipes_filters_section {
    padding: 0;

}
@media screen and (max-width: 991px){
    .recipes_filters_section {
        padding: 0 !important;

    }
}

.recipes_section {
    
    display: flex;
    /*padding: 5rem 32rem;
    justify-content: space-around;*/
    padding: 5rem 0px;
    justify-content: center;
    background-color: #f5f5f5;
    background-image: url('../../assets/images/recetas-bg.webp');
    background-position: center center;
    background-repeat: no-repeat;
}

.recipes_section_margin {
    margin-top: 75vh;
}

.section_subtitle {
    color: #F06400;
    margin-top: 2rem;
}

.filter {
    /*width: -webkit-fill-available;
    min-width: max-content;
    margin-right: 1rem;*/
    margin-right: 20px; 
    margin-left: 20px; 
    width: 320px;
}
.recipes_section {
    justify-content: left;
}

/*.recipe-grid {
    /*min-width: -webkit-fill-available;*/
    
/*}*/


.recipe-grid {
        width: 100%;
}


@media (min-width:1200px) {
    .recipes_section {
        justify-content: center;
    }
    .recipe-grid {
        width: 800px;
    }
    .filter {
        margin-right: 0px; 
        width: 320px;
    }
}
@media (min-width:1600px) {
    .recipe-grid {
        width: 1000px;
    }
}
@media (min-width:1800px) {
    .recipe-grid {
        width: 1000px;
    }
}
.space_box {
    height: 3rem;
}

.filter_item {
    margin: 0;
    cursor: pointer;
    color: #3a3a3a;
    font-weight: 300;
    font-family: "KH-Grotesk", Sans-serif;
}

.recipe {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 3rem;
    transition: all 1s ease;

}

.recipe:hover {
    transform: scale(1.1);
}

.recipe_img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
}

.recipe_title {
    color: #97340D;
    font-weight: 600;
}

.diff_time_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.difficulty_container {
    font-size: 14px;
    align-items: baseline;
    display: flex;
}

.difficulty {
    color: #F06400;
    margin-left: 0.5rem;
}

.check_input {
    margin-right: 0.3rem;
    cursor: pointer;
}

.search_input_container {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}

.search_input {
    height: 3.5rem;
    width: 22rem;
    border: none;
    background-color: rgb(236, 236, 236);
    padding: 1rem;
    outline: none;
}

.search_btn {
    height: 3.5rem;
    width: 3.5rem;
    background-color: #FFA52B;
    border: none;
}

.search_btn:hover {
    background-color: #E3012B;
}

.hour_icon,
.signal_icon {
    filter: invert(48%) sepia(50%) saturate(1600%) hue-rotate(180deg) brightness(80%) contrast(70%);
    width: 0.8rem;
    margin-right: 0rem;
}

.signal_icon {
    margin-right: 0.5rem;
    width: 1.3rem;
}

.search_icon {
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
    width: 1.2rem;
}

.cap-each{
    text-transform: capitalize;
}

@media (max-width:1800px) {
    .recipes_section {
        /*padding: 2rem 15rem;*/
        padding: 2rem 0px;
    }

}

@media (max-width:1400px) {
    .recipes_section {
        /*padding: 2rem 15rem;*/
         padding: 2rem 0px;
    }

}

@media (max-width:1200px) {
    .recipes_section {
        /*padding: 2rem 5rem;*/
        padding: 2rem 0px;
        flex-wrap: wrap;

    }

}

@media (max-width:900px) {
    .recipes_section {
        /*padding: 2rem 2rem;*/
        padding: 2rem 0px;
        flex-wrap: wrap;
    }

    .search_input {
        width: 100%;
    }

}

@media (max-width:700px) {
    .recipes_section_margin {
        margin-top: 50vh;
    }

}

@media (max-width:500px) {
    .recipes_section {
        padding: 0.5rem;
    }

}