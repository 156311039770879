@import '../../styles/bootstrap-utilities.scss';
.header-background {
    height: 20vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffa52b;
    @include media-breakpoint-up(lg) {
        height: 11.3rem;
    }
}
