@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';
.NavProductos {
    &.NavProductosOverrideClass {
        --navbar-font-family: var(--font-roboto);
        --navbar-margin-top: 0;
        --navbar-bg-image: var(--color-orange-3);
        --navbar-z-index: 99;
        --navbar-padding: 0;
        --navbar-color-link: var(--color-white);
        --navbar-list-background: var(--color-orange-4);
        --navbar-list-item-width: 100%;
        --navbar-link-hover-background: var(--color-dark-grey);
        --navbar-link-active-background: var(--color-orange-3);
        --navbar-link-transition: background-color 0.4s ease;
        --navbar-link-text-transform: capitalize;
        --navbar-link-font-size: 1rem;
        --navbar-link-padding: 0.625rem 0;
        --navbar-before-pseudo-element-display: none;
        @include media-breakpoint-up(lg) {
            --navbar-font-family: var(--font-hkGroteskRegular);
            --navbar-margin-top: -11.5rem;
            --navbar-bg-image: transparent;
            --navbar-padding: 11.5rem 0 0;
            --navbar-color-link: var(--color-black);
            --navbar-list-background: transparent;
            --navbar-list-item-width: 12%;
            --navbar-link-hover-background: transparent;
            --navbar-link-active-background: transparent;
            --navbar-link-transition: none;
            --navbar-link-text-transform: lowercase;
            --navbar-link-font-size: clamp(0.8125rem, 1.2vw, 1rem);
            --navbar-link-first-letter-text-transform: uppercase;
            --navbar-link-padding: 0.625rem;
            --navbar-pseudo-element-z-index: -1;
            --navbar-before-pseudo-element-display: block;
            --navbar-before-pseudo-element-bg: linear-gradient(
                180deg,
                var(--color-orange-4) 65%,
                var(--color-white) 45%
            );
            --navbar-list-padding-top: 3.125rem;
        }
        background: var(--navbar-bg-image);
        padding: var(--navbar-padding);
        margin-top: var(--navbar-margin-top);
        min-height: auto;
        .NavProductosBrand {
            padding: 1rem;
        }
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: var(--navbar-pseudo-element-z-index);
            background: var(--navbar-before-pseudo-element-bg);
            display: var(--navbar-before-pseudo-element-display);
        }
    }
    &Toggler {
        height: 1.375rem;
        width: 2.1875rem;
        top: 1.2rem;
        right: 1rem;
        &Active {
            .NavProductosTogglerLine {
                &.line {
                    &--1 {
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &--2 {
                        -webkit-transform: scaleY(0);
                        transform: scaleY(0);
                    }
                    &--3 {
                        -webkit-transform: rotate(-45deg) translateX(-0.125rem);
                        transform: rotate(-45deg) translateX(-0.125rem);
                    }
                }
            }
        }
        &Line {
            --line-transition-duration: 0.3s;
            height: 0.1875rem;
            width: 90%;
            border-radius: 0.625rem;
            background-color: var(--color-white);
            -webkit-transition: var(--line-transition-duration) ease-in-out;
            transition: var(--line-transition-duration) ease-in-out;
            &.line {
                &--1 {
                    -webkit-transform-origin: 0% 0%;
                    transform-origin: 0% 0%;
                }
                &--2 {
                    --line-transition-duration: 0.1s;
                }
                &--3 {
                    -webkit-transform-origin: 0% 100%;
                    transform-origin: 0% 100%;
                }
            }
        }
    }
    &List {
        background-color: var(--navbar-list-background);
        width: 100%;
        max-width: 73.125rem;
        padding-top: var(--navbar-list-padding-top);
        &Item {
            width: var(--navbar-list-item-width);
        }
    }

    &Link {
        padding: var(--navbar-link-padding);
        -webkit-transition: var(--navbar-link-transition);
        transition: var(--navbar-link-transition);
        p {
            font-size: var(--navbar-link-font-size);
            line-height: 1.3;
            font-weight: 500;
            color: var(--navbar-color-link);
            text-transform: var(--navbar-link-text-transform);
            font-family: var(--navbar-font-family);
            &::first-letter {
                text-transform: var(--navbar-link-first-letter-text-transform);
            }
        }
        &:hover {
            background-color: var(--navbar-link-hover-background);
            color: var(--navbar-color-link);
        }
        &Active,
        &Active:hover {
            background-color: var(--navbar-link-active-background);
        }
        &Active {
            .NavProductosImage {
                opacity: 0;
                visibility: hidden;
            }
            .NavProductosImageActive {
                opacity: 1;
                visibility: visible;
                -webkit-transition-delay: 0.3s;
                transition-delay: 0.3s;
            }
        }
    }
    &Image,
    &ImageActive {
        -webkit-transition: visibility 0.5s ease, opacity 0.5s ease;
        transition: visibility 0.5s ease, opacity 0.5s ease;
    }
    &Image {
        width: 100%;
        &Active,
        &Sprite {
            top: 0;
            left: 0;
            height: 100%;
        }
        &Active {
            opacity: 0;
            visibility: hidden;
        }
        &Sprite {
            background: url('../../assets/images/smoke-sprite.webp') no-repeat
                top left;
            background-position: 0 -0.5rem;
            background-size: 4800% 100%;
            -webkit-animation: smoke 2s steps(47) infinite;
            animation: smoke 2s steps(47) infinite;
            -webkit-filter: brightness(1.4) blur(0.0625rem);
            filter: brightness(1.4) blur(0.0625rem);
            opacity: 0.85;
        }
    }
}

@-webkit-keyframes smoke {
    to {
        background-position-x: 100%;
    }
}

@keyframes smoke {
    to {
        background-position-x: 100%;
    }
}
