.hero_slides {
    position: absolute;
    top: 0;
    z-index: -1;
    background-image: url('../../assets/images/Recetas-slide-3.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    animation-name: sliderchange;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    text-align: center;
    max-width: -webkit-fill-available;
    padding: 0;
}

.hero_img {
    opacity: 0;
    height: 100vh;
}

.hero_content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: -webkit-fill-available;
    margin-top: 2rem;
}

.hero_content h1 {
    font-family: 'nickainleyregular';
    font-weight: 300;
    transform: rotate(-5deg);
    font-size: 5rem;
    font-size: 5.3125rem;

}

.hero_content p {
    color: white;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.3rem;
    font-size: 1rem;
}

@media (max-width:700px) {
    .hero_content {
        top: 60%;
    }

    .hero_content h1 {
        font-size: 3.5rem;
        top: 60%;
    }

    .hero_img {
        opacity: 0;
        height: 70vh;
    }
}

@media screen and (max-width: 991px){
    .hero_slides{
        height: 550px;
    }
    .hero_content {
        width: 230px !important;
        top: 260px !important;
    }
    .hero_content h1 {
        font-size: 2.5rem !important;
    }
    .recipes_section_margin {
        margin-top: 400px;
    }
}

@media (max-height:900px){
    .hero_content{
        margin-top: 5rem!important;
    }
}

@keyframes sliderchange {
    30% {
        background-image: url('../../assets/images/Recetas-slide-3.webp');
    }

    60% {
        background-image: url('../../assets/images/Receta3-1.webp');
    }

    90% {
        background-image: url('../../assets/images/Receta1.webp');
    }

    100% {
        background-image: url('../../assets/images/Recetas-slide-3.webp');
    }
}