#alx-header-news{
  background-color: #ff8101;
  /*background-image: linear-gradient(rgb(255, 165, 43) 100%, rgb(255, 255, 255) 0%):*/

}
.alx-color-cafe{
  color: #58392c;
}
.alx-color-naranja{
  color: #ff8101;
}
/*.alx-color-cafe{
  color: #212529; 
}*/
.alx-bold{
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  #alx-logo-header{
      top: -20px;
      position: absolute;
      left: 50%;
      width: 220px;
      height: auto;
      margin-left: -110px;
  }
  .alx-opc-menu{
    margin-top: 90px;
  }
  #basic-navbar-nav{
    position: relative;
    z-index: 2;
  }
  #alx-rs-header{
    z-index: 3;
    width: 300px;
    right: 0px;
  }
}






.alx-back-seccion{
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
}
.alx-banner{
    margin-bottom: 40px;
}
.alx-img-full{
    width: 100%;
    max-width: 100%;
}
.alx-receta-min{
    margin-bottom: 40px;
    display: block;
}
.alx-receta-min img{
    margin-bottom: 10px;
    width: 100% !important;

}


@media only screen and (max-width: 991px) {

    .alx-back-seccion{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .alx-banner{
        margin-bottom: 10px;
        margin-top: 15px;
    }
  
}

@media only screen and (max-width: 575px) {

    .alx-back-seccion{
        padding-top: 0px;
        padding-bottom: 20px;
    }
    
    #alx-div-banner-home-noticias{
        padding-left: 0px;
        padding-right: 0px;
    }
}
