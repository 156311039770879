.related-recipes-grid {
    div {
        height: 250px;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        };
    }

    h3 {
        font-size: 20px;
    }
}
h3.capitalize{
    text-transform: capitalize;
}