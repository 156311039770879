section {
    padding: 0;

    .mi_toque_isadora_spacer {
        height: 70vh;
    }

    .string_contents {
        padding: 5rem 4.5rem;

        .miToqueIsadoraTitle {
            color: #F06400;
            font-size: 3.125rem;
            font-weight: 400;
            margin-bottom: 1rem;
        }

        .text {
            width: 48%;
        }

        p {
            margin-bottom: 0;
            font-weight: 400;
            line-height: 1.3rem;
            font-size: 1rem;

        }
    }
    .little_space{
        height: .5rem;
    }
}

@media (max-width:800px) {
    section {

        .string_contents {
            padding: 0;

            h1 {
                font-size: 2.5rem;
            }

            .text {
                width: 80%;
                margin-bottom: 6rem;
            }

        }
    }

}

@media (max-width:700px) {
    section {
        padding: 1rem;

        .mi_toque_isadora_spacer {
            height: 60vh;
        }

        .string_contents {
            padding: 0;

            h1 {
                font-size: 2.25rem;
            }

            .text {
                width: 100%;
                margin-bottom: 6rem;
            }

        }
    }

}