.h3{
    font-family: "MontserratRegular", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    color: #603811;
    font-size: 1.75rem;
    letter-spacing: 1px;
}

#emailNewsletter
{
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0;
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

#submitLead{
    font-family: "MontserratRegular", sans-serif;
    padding: 1rem;
    font-weight: 400;
}