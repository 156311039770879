@use '../../styles/variables.scss' as *;
@import '../../styles/bootstrap-utilities.scss';

.hero {
    --hero-bg-homepage: url('../../assets/media/home-movil.webp');
    --hero-padding-y: 1.875rem;
    --hero-bg-mi-toque-isadora: url('../../assets/media/Mi-toque-isadora.png');
    --hero-bg-frijol-super-alimento: url('../../assets/media/FSA-B1-Backgroun.jpg');
    --hero-heading-font-size: 3.5rem;
    --hero-img-plato-max-width: 31.25rem;
    @include media-breakpoint-up(md) {
        --hero-bg-homepage: url('../../assets/media/IsadoraHome_2024.webp');
        --hero-heading-font-size: 4.4375rem;
        --hero-padding-y: 4.6875rem;
        $variables: --hero-mi-toque-isadora-padding-t,
            --hero-mi-toque-isadora-padding-b, --hero-frijol-padding-t,
            --hero-frijol-padding-b;
        @each $var in $variables {
            $var: var(--hero-padding-y);
        }
        --hero-img-plato-top: 2rem;
        --hero-img-plato-position: absolute;
        --hero-max-height: 50rem;
    }
    @include media-breakpoint-up(lg) {
        --hero-bg-margin-top: -10.25rem;
        --hero-padding-y: 10.25rem;
        --hero-mi-toque-isadora-padding-t: 12.5rem;
        --hero-mi-toque-isadora-padding-b: 5rem;
        --hero-frijol-padding-t: 18.75rem;
        --hero-frijol-padding-b: 12.5rem;
        --hero-heading-font-size: 5.3125rem;
        --hero-img-plato-top: 0;
        --hero-max-height: 53.125rem;
        --hero-img-plato-max-width: 39rem;
    }
    @include media-breakpoint-up(xxl) {
        --hero-description-home-max-width: 16.25rem;
        --hero-description-home-left: -1.25rem;
    }
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: var(--hero-bg-margin-top);
    padding: var(--hero-padding-y) 0 3.125rem;
    max-height: var(--hero-max-height);
    min-height: 60vh;
    p {
        font-family: var(--font-roboto);
    }
    &Homepage {
        background-image: var(--hero-bg-homepage);
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(rgba(0, 0, 0, 0.7)),
                color-stop(30%, rgba(0, 0, 0, 0)),
                color-stop(50%, rgba(2, 2, 2, 0)),
                color-stop(80%, rgba(0, 0, 0, 0)),
                to(rgba(0, 0, 0, 0.7))
            );
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0) 30%,
                rgba(2, 2, 2, 0) 50%,
                rgba(0, 0, 0, 0) 80%,
                rgba(0, 0, 0, 0.7) 100%
            );
        }
    }
    &MiToqueIsadora {
        background-image: var(--hero-bg-mi-toque-isadora);
        padding: var(--hero-mi-toque-isadora-padding-t) 0
            var(--hero-mi-toque-isadora-padding-b);
        &::before {
            content: '';
            width: 100%;
            height: 60%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(
                to top,
                rgba(255, 255, 255, 0.9),
                transparent
            );
        }
        &Img {
            width: 80%;
            max-width: 28.1875rem;
        }
        &CopyContainer {
            max-width: 24.0625rem;
        }
    }
    &FrijolSuperAlimento {
        background-image: var(--hero-bg-frijol-super-alimento);
        padding: var(--hero-frijol-padding-t) 0 var(--hero-frijol-padding-b);
        .heroMainImg {
            width: 5.3125rem;
        }
        .heroMainTitle {
            max-width: 100%;
        }
        .heroSecondaryTitle {
            font-size: 1.25rem;
        }
    }
    &Main {
        &Title {
            font-size: var(--hero-heading-font-size);
            max-width: 43.75rem;
            line-height: 1;
            &Container {
                width: 90%;
                padding-top: 4rem;
            }
        }
        &Img {
            &Plato {
                max-width: var(--hero-img-plato-max-width);
                top: var(--hero-img-plato-top);
            }
            &Flecha {
                left: -5rem;
            }
        }
        &DescriptionHomeContainer {
            &.heroMainDescriptionHomeContainerOverride {
                max-width: var(--hero-description-home-max-width);
                left: var(--hero-description-home-left);
            }
        }
    }
}
