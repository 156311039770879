.navToggle {
    height: 1.375rem;
    width: 2.1875rem;
    &Active {
        .navToggleLine {
            &.navToggleLine {
                &--1 {
                    -webkit-transform: rotate(45deg)
                        translate(0.25rem, -0.5625rem);
                    transform: rotate(45deg) translate(0.25rem, -0.5625rem);
                }
                &--2 {
                    -webkit-transform: scaleY(0);
                    transform: scaleY(0);
                }
                &--3 {
                    -webkit-transform: rotate(-45deg)
                        translate(0.375rem, 0.25rem);
                    transform: rotate(-45deg) translate(0.375rem, 0.25rem);
                }
            }
        }
    }
    &Line {
        --line-transition-duration: 0.3s;
        height: 0.1875rem;
        width: 90%;
        border-radius: 0.625rem;
        background-color: var(--color-white);
        -webkit-transition: var(--line-transition-duration) ease-in-out;
        transition: var(--line-transition-duration) ease-in-out;
        &.navToggleLine {
            &--1 {
                -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
            }
            &--2 {
                --line-transition-duration: 0.1s;
            }
            &--3 {
                -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
            }
        }
    }
}
