@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

@font-face {
    font-family: 'nickainleyregular';
    src: url('../../assets/fonts/nickainley-webfont.woff2') format('woff2'),
        url('../../assets/fonts/nickainley-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dk_rosy_leeregular';
    src: url('../../assets/fonts/dk_rosy_lee-webfont.woff2') format('woff2'),
        url('../../assets/fonts/dk_rosy_lee-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Roboto', sans-serif;
}

.animate__animated {
    -webkit-animation-duration: 2s !important;
    animation-duration: 2s !important;
}

* {
    margin: 0;
    padding: 0;
}

.tac {
    text-align: center;
}

.butop {
    text-align: right;
    margin-bottom: 20px;
}

.ban-left h5 {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
}

.ban-left p {
    margin-bottom: 30px;
    color: #ffffff;
    line-height: 21px;
}

.ban-left a {
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    text-decoration: none;
    border: solid 1px #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 17px;
}

.ban-left a:hover {
    background-color: #e3012b;
    border: solid 1px #e3012b;
}

.reec {
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    text-decoration: none;
    border: solid 1px #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 17px;
    color: #fff !important;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 20px;
}

.reec:hover {
    background-color: #e3012b;
    border: solid 1px #e3012b;
    color: #fff !important;
    text-decoration: none;
}

.modal-content {
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 0%);
    box-shadow: 0 5px 15px rgb(0 0 0 / 0%);
    color: #fff;
    background-color: transparent !important;
    border: hidden;
    text-align: center;
    padding-top: 45vh;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: transparent;
    background-image: none;
    border: hidden;
    border-radius: 0;
    border-bottom: solid 1px #fff;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
}

.close {
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=100);
    opacity: 1;
    text-align: right;
    margin-right: -50px;
}

.modal.in .modal-dialog {
    width: 90%;
    max-width: 80%;
}

.dish {
    width: 350px;
    height: auto;
    margin-bottom: -50px;
}

.conoce {
    border: solid 1px #000;
    color: #000 !important;
}

.icons {
    margin-bottom: 20px;
}

.form-control:focus {
    color: #fff !important;
    background-color: transparent !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 0%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 0%) !important;
    border-color: transparent;
    border-bottom: solid 1px #fff !important;
}

.nav > li > a:focus,
.nav > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #fff !important;
    border-bottom: solid 1px #fff;
}

.icons a {
    text-decoration: none !important;
}

.nav > li > a {
    display: inline;
}

.det {
    max-width: 150px !important;
    margin-top: 20px;
}

.platos {
    position: relative;
}

.platos .dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 85%;
}

#contact-home {
    background: url(../../assets/media/Fondo-textura-mimbre.png);
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 100px 0;
    text-align: center;
}

#contact-home h3 {
    color: #ffffff;
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 60px;
}

#contact-home p {
    color: #ffffff;
    text-align: center;
    max-width: 580px;
    margin: auto;
    font-size: 16px;
    margin-bottom: 20px;
}

#nuestro,
#salud {
    overflow: hidden;
}

#nuestro {
    padding: 100px 0;
    text-align: center;
}

#nuestro h3 {
    font-family: 'dk_rosy_leeregular';
    font-size: 89px;
    font-weight: 400;
}

#nuestro p {
    max-width: 400px;
    font-size: 16px;
    margin: 30px auto;
}

.col-frijo h2 {
    font-family: 'Amatic SC', cursive;
    color: #f06400;
    font-size: 65px;
    font-weight: 700;
}

#nuestro .col-frijo p {
    max-width: 400px;
    font-size: 16px;
    margin: 10px auto;
}

#salud {
    padding: 100px 0;
    background-color: #fff1df;
}

#salud h2 {
    color: #020101;
    font-family: 'dk_rosy_leeregular';
    font-weight: 400;
    font-size: 89px;
    max-width: 550px;
}

.bowl {
    text-align: center;
    padding: 10px 0;
    padding-top: 1rem;
    min-height: 33rem;
}

.bottom-bowl {
    padding-top: 5rem;
}

.bowl h4 {
    font-family: 'nickainleyregular';
    text-align: center;
    color: #f06400;
    font-size: 45px;
    font-weight: 400;
}

.bowl p {
    max-width: 15rem;
    font-size: 16px;
    margin: 10px auto;
}

.dashed {
    border-bottom: dashed;
}

.sal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.rela {
    position: relative;
}

.topla {
    margin-top: -100px;
    position: relative;
    top: 5.5vw;
}

.fle1 {
    float: left;
    position: relative;
    top: 5vw;
}

.fle1-bottom {
    position: relative;
    top: -14rem;
    left: -6rem;
}

.fle2-top {
    position: relative;
    top: 12rem;
    left: 2rem;
}

.fle2-bottom {
    position: relative;
    top: -2rem;
}

.fle2 {
    float: right;
}

#fuente {
    background: #ffb24b;
    padding-bottom: 100px;
    padding: 0 0 6.25rem 0;
}

.fuente-top {
    max-width: 100%;
}

.fuente-datos {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.fuente-datos h2 {
    color: #fff;

    font-weight: 400;
    font-family: 'Amatic SC', cursive;
    font-size: 60px;
}

.fuente-datos p {
    font-size: 16px;
    margin: 10px 0;
}

.fuente-datos div {
    padding: 15px;
}

.tillo-fle {
    position: absolute;
    right: 6%;
    top: -5%;
}

.tillo {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: -20rem;
    text-align: end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.sixtillo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tillop {
    font-size: 15px;
    color: #000;
    max-width: 300px;
    margin: auto;
}

.tillo-text {
    margin-bottom: -7rem;
    margin-left: -7rem;
}

.tilloas {
    font-size: 11px;
    color: #000;
    max-width: 300px;
    margin: auto;
    padding-top: 1.5rem;
}

#mexico {
    padding: 100px 0;
    position: relative;
    padding-top: 18rem;
}

#mexico .container {
    position: relative;
}

.plafoot {
    width: 320px;
    position: absolute;
    top: -180px;
    right: 40px;
}

#mexico>.container>h2{
    line-height: 1;
}

#mexico h2 {
    color: #020101;
    font-family: 'dk_rosy_leeregular';
    font-weight: 400;
    font-size: 89px;
    max-width: 550px;
}

#mexico h3 {
    font-family: 'Amatic SC', cursive;
    color: #f06400;
    font-size: 65px;
    font-weight: 700;
}

#mexico p {
    font-size: 16px;
    margin: 10px 0;
}

.mex {
    margin-top: -100px;
}

.mt-130px
{
    margin-top: -130px;
    margin-left: -20px;
}

.linn {
    margin: 20px 0;
}

#anuncio {
    background-color: rgba(0, 0, 0, 0.7);
}

#anuncio .modal-content {
    padding-top: 0;
    max-width: 700px;
    margin: auto;
}

#anuncio .modal-content img {
    max-width: 700px;
    border: solid 10px #fff;
}

.robotoReg {
    font-family: 'RobotoRegular';
}

#frij-70-varied {
    position: relative;
}

.line-height-normal {
    line-height: 1;
}
p.robotoReg.smaller-max-width {
    max-width: 13rem;
}

#mexico > .container > h2 {
    margin-bottom: 5rem;
}

#salud {
    z-index: 9999;
}

#imagenDeFuenteBeans {
    z-index: 21;
    position: relative;
    top: -30rem;
    -webkit-transition: top 1.5s ease;
    transition: top 1.5s ease;
}

#beansFuente {
    overflow: hidden;
}

@media screen and (max-width: 1400px) {
    .fle1-bottom {
        top: -12rem;
    }
}

@media screen and (max-width: 1200px) {
    .tillo-text {
        margin-left: -2rem;
    }

    .fle1-bottom {
        top: -7rem;
    }

    .fle2-top {
        top: 9rem;
        left: 2rem;
    }

    .fle2-bottom {
        top: -2rem;
    }
}
@media screen and (max-width: 1000px) {
    .tillo-fle {
        top: 2%;
    }
}
.customBlack {
    color: black;
}

@media screen and (max-width: 991px) {
    .taar {
        text-align: center;
    }

    #anuncio .modal-content img {
        max-width: 700px !important;
        border: solid 10px #fff;
        margin: auto !important;
    }

    #anuncio .modal-dialog {
        width: 90% !important;
        max-width: 90% !important;
        margin: auto;
        margin-top: 100px;
    }

    .visita {
        display: none !important;
    }

    .header-interna {
        padding-top: 0;
    }

    .header-interna .headerItem {
        color: #fff !important;
        font-weight: bolder;
    }

    #en-casa .toque h2 {
        font-size: 30px !important;
        line-height: 40px;
        text-align: center !important;
    }

    #en-casa {
        padding: 50px 0;
        min-height: 400px;
        position: relative;
        padding-bottom: 50px;
        text-align: center;
    }

    #en-casa .toque p {
        text-align: center !important;
        margin: 0 !important;
        max-width: 540px !important;
    }

    .platos .dot {
        width: 55%;
    }

    #nuestro h3 {
        font-family: 'dk_rosy_leeregular';
        font-size: 49px;
        font-weight: 400;
    }

    #salud h2 {
        font-size: 49px;
        text-align: center;
    }

    .bowl {
        padding: 50px 0;
    }

    .fuente-datos {
        display: block;
        text-align: center;
    }

    .plafoot {
        display: none;
    }

    #mexico h2 {
        text-align: center;
        font-size: 49px;
    }

    #mexico h3 {
        font-size: 45px;
        text-align: center;
    }

    #mexico p {
        text-align: center;
    }

    .fle1 {
        display: none;
    }

    .fle2 {
        display: none;
    }

    .topla {
        margin-top: -40px;
        margin-bottom: 0;
    }

    .redes {
        z-index: 1;
        top: 110px;
        width: 94%;
        left: 2%;
        right: 2%;
    }

    section {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .tillo {
        display: block;
        text-align: center;
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    .hide-on-mobile {
        display: none;
    }
    .tillo {
        margin-bottom: 0;
    }
    .tillo-text {
        margin-bottom: 0;
        text-align: center;
    }
    .sixtillo {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.custom-max-width {
    max-width: 105% !important;
}
