#noticiasContainer {
    max-width: 85%;
    margin-top: 5rem;
}

.shadow-text {
    text-shadow: 2px 1px 2px #565656;
}

.line {
    color: white;
    opacity: 1;
    margin: 0.2rem 0;
}

.linear-bottom {
    border-bottom: 2px solid #ffffff;
}

p.card-text {
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 1px;
}

h5.card-title {
    font-size: 0.8rem;
}

.card {
    max-height: 100%;
    height: 100%;
    max-width: 30rem;
    overflow: hidden;
}

.card-img {
    filter: brightness(0.7);
    height: 100%;
    /*max-height: 435px;*/
}

div.col-sm-12.col-md-6.col-lg-4{
    max-width: 370px;
    margin: auto;
}

.newsletter {
    margin: 5rem 0 5rem 0;
}

p.h3{
    color: #603811;
}

.form-control {
    border-color: white white #565656 white;
    padding: 0px 15px;
}

.btn-light {
    padding: 1rem;
}

#error, #success {
    display: none;
}

.each-post-col{
    height: 13.5rem;
}
